import request from "@/utils/request";

// HomeKanban
export function stockAgeRatio(params) {
  return request({ url: `/home_kanban/stock_age_ratio/`, method: "get", params });
}

export function stockInStats(params) {
  return request({ url: `/home_kanban/stock_in_stats/`, method: "get", params });
}

export function stockOutStats(params) {
  return request({ url: `/home_kanban/stock_out_stats/`, method: "get", params });
}

export function stockInTrend(params) {
  return request({ url: `/home_kanban/stock_in_trend/`, method: "get", params });
}

export function stockOutTrend(params) {
  return request({ url: `/home_kanban/stock_out_trend/`, method: "get", params });
}

export function inventoryWarning(params) {
  return request({ url: `/home_kanban/inventory_warning/`, method: "get", params });
}

export function inventoryProportion(params) {
  return request({ url: `/home_kanban/inventory_proportion/`, method: "get", params });
}

export function inventoryTrend(params) {
  return request({ url: `/home_kanban/inventory_trend/`, method: "get", params });
}
